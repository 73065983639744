<template>
  <section
    v-if="!isMobile"
    ref="section"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="homepage-category-list"
  >
    <div ref="container" class="categories-container">
      <div class="images-container">
        <div class="gradient" />
        <template
          v-for="(category, index) in slice.primary.category"
          :key="index"
        >
          <div
            ref="images"
            class="image"
            :style="{
              zIndex: slice.primary.category.length - index,
            }"
          >
            <CustomImage :image="category.image" :sizes="[393, 1440]" />
          </div>
        </template>

        <div class="content">
          <div class="links-wrapper">
            <div class="links-container">
              <ul class="category-link-list">
                <svg
                  ref="circle"
                  class="circle"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                >
                  <circle
                    cx="6"
                    cy="6"
                    r="5"
                    fill="none"
                    stroke="darkgrey"
                    stroke-width="1"
                  />
                  <circle
                    ref="circleWhite"
                    cx="6"
                    cy="6"
                    r="5"
                    fill="none"
                    stroke="white"
                    stroke-width="1"
                    stroke-dasharray="31.4"
                    stroke-dashoffset="31.4"
                    transform="rotate(-90 6 6)"
                  />
                </svg>

                <template v-for="(category, index) in slice.primary.category">
                  <li>
                    <button
                      ref="categoryLink"
                      class="category-link"
                      @click="jumpTo(index)"
                    >
                      {{ category.name }}
                    </button>
                  </li>
                </template>
              </ul>
              <div class="all-products-container">
                <Link
                  ref="seeAllLink"
                  label="See All Products"
                  :light="true"
                  @click="onAllProductsClick"
                />

                <div ref="otherLinks" class="others-container">
                  <NuxtLink to="/baths">Baths</NuxtLink>,
                  <NuxtLink to="/wardrobe">Wardrobe</NuxtLink>,
                  <NuxtLink to="/doors">Doors</NuxtLink>,
                  <NuxtLink to="/appliances">Appliances</NuxtLink>
                </div>
              </div>
            </div>
          </div>

          <div class="titles-container">
            <ul class="category-titles-list">
              <template v-for="(category, index) in slice.primary.category">
                <li class="c-l">
                  <h2 ref="categoryTitle" class="category-title">
                    <span :data-index="index" class="split-letters">
                      {{ category.name }}
                    </span>
                  </h2>
                  <p ref="categoryShipping" class="shipping-info">
                    <span :data-index="index" class="split-letters">{{
                      category.shipping
                    }}</span>
                  </p>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

import { debounce } from "throttle-debounce";
import { expDecay } from "@/utils/lerp";
import { Lethargy } from "lethargy-ts";

import Link from "@/components/Link";
import { type Content } from "@prismicio/client";

import { useMobile } from "@/composables/useMobile";

const lethargy = new Lethargy({
  sensitivity: 0,
  delay: 50,
  increasingDeltasThreshold: 3,
});

const { isMobile } = useMobile();

const { $lenis } = useNuxtApp();

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
  getSliceComponentProps<Content.CategoryListHomepageSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ])
);

const section = shallowRef(null);
const container = shallowRef(null);

const images = ref([]);
const categoryTitle = ref([]);
const categoryShipping = ref([]);
const categoryLink = ref([]);

const otherLinks = shallowRef(null);
const seeAllLink = shallowRef(null);

const selfProgress = ref(0);

// Dans le setup du composant
const oldIndex = ref(0);
const currentIndex = ref(0);

const circle = shallowRef(null);
const circleWhite = shallowRef(null);

const isPinned = ref(false);

const router = useRouter();

let ctx;

const count = computed(() => {
  return props.slice.primary.category.length;
});

const desktopEnabled = ref(false);

onMounted(() => {
  watch(
    isMobile,
    (newVal) => {
      if (newVal === false) {
        nextTick(() => {
          if (desktopEnabled.value === true) return;
          initDesktop();
          ScrollTrigger.refresh(true);
        });
      } else {
        if (desktopEnabled.value === false) return;
        shutDesktop();
      }
    },
    {
      immediate: true,
    }
  );
});

const initDesktop = () => {
  gsap.set(otherLinks.value, {
    yPercent: 100,
    autoAlpha: 1,
    overwrite: true,
  });

  desktopEnabled.value = true;
  splitTexts();
  initAnimations();
  initListeners();

  initDesktopListeners();

  gsap.ticker.add(updateTracker);
  onResize();
  updateContent(0);
};

const splitTexts = () => {
  ctx = gsap.context(() => {
    section.value.querySelectorAll(".split-letters").forEach((element) => {
      const split = new SplitText(element, {
        type: "lines, chars",
        charsClass: "--char",
        linesClass: "--line",
      });

      if (element.dataset.index > 0) {
        if (split.chars.length) {
          gsap.set(split.chars, {
            yPercent: 100,
          });
        }
      }
    });
  });
};

const initAnimations = () => {
  pinSection();
};

const pinSection = () => {
  ctx.add(() => {
    ScrollTrigger.create({
      trigger: section.value,
      start: `top top`,
      end: "bottom top",
      onUpdate: (self) => {
        onScrollUpdate(self);
        calculateSegmentedProgress(self.progress);
      },
    });

    ScrollTrigger.create({
      trigger: section.value,
      start: `top top`,
      end: "bottom bottom",
      onEnter: () => {
        isPinned.value = true;
      },
      onLeave: () => {
        isPinned.value = false;
      },
      onEnterBack: () => {
        isPinned.value = true;
      },
      onLeaveBack: () => {
        isPinned.value = false;
      },
    });
  });
};

const elementProgress = ref(Array(images.value.length).fill(0));

const onScrollUpdate = (self) => {
  const progress = self.progress;

  const totalImages = count.value;
  let newIndex = 0;

  if (self.progress <= 0) {
    newIndex = 0;
    elementProgress.value = Array(totalImages).fill(0);
  } else if (self.progress <= 1) {
    images.value.forEach((image, index) => {
      const startProgress = index * (1 / totalImages);
      const endProgress = (index + 1) * (1 / totalImages);
      const baseProgress = Math.max(
        -1,
        Math.min(
          1,
          gsap.utils.mapRange(startProgress, endProgress, 0, 1, progress)
        )
      );

      ctx.add(() => {
        if (index === totalImages - 1) {
          gsap.set(image, {
            yPercent: Math.abs(baseProgress) * 50,
            clipPath: `inset(0% 0% ${baseProgress * 50}% 0%)`,
          });
        } else {
          gsap.set(image, {
            yPercent: baseProgress * -50,
            clipPath: `inset(0% 0% ${baseProgress * 50}% 0%)`,
          });
        }
      });
    });
  } else {
    elementProgress.value = Array(totalImages).fill(1);
  }

  newIndex = Math.min(
    totalImages - 1,
    gsap.utils.snap(1 / totalImages, progress) * totalImages
  );

  if (currentIndex.value !== newIndex) {
    oldIndex.value = currentIndex.value;
    currentIndex.value = newIndex;

    if (currentIndex.value === totalImages - 1) {
      gsap.to(otherLinks.value, {
        yPercent: 0,
        opacity: 1,
        duration: 0.8,
        ease: "expo.out",
      });
      gsap.to(seeAllLink.value.linkElement.$el, {
        yPercent: -100,
        opacity: 0,
        duration: 0.8,
        ease: "expo.out",
      });
    } else {
      gsap.to(seeAllLink.value.linkElement.$el, {
        yPercent: 0,
        opacity: 1,
        duration: 0.8,
        ease: "expo.out",
      });
      gsap.to(otherLinks.value, {
        yPercent: 100,
        opacity: 0,
        duration: 0.8,
        ease: "expo.out",
      });
    }
  }

  selfProgress.value = self.progress;
};

const trackerPercentage = ref(0);
const lerpedPercentage = ref(0);

const calculateSegmentedProgress = (progress) => {
  // Ensure progress is between 0 and 1
  const clampedProgress = Math.max(0, Math.min(1, progress));

  // Define segment boundaries
  const boundaries = [0, 0.125, 0.375, 0.625, 1];

  // Determine which segment we're in
  let segment = 0;
  for (let i = 1; i < boundaries.length; i++) {
    if (clampedProgress <= boundaries[i]) {
      segment = i - 1;
      break;
    }
  }

  // Calculate the progress within the current segment
  const segmentStart = boundaries[segment];
  const segmentEnd = boundaries[segment + 1];
  const segmentProgress =
    (clampedProgress - segmentStart) / (segmentEnd - segmentStart);

  // Convert to percentage (0-100)
  const percentTracker = Math.round(segmentProgress * 100);

  trackerPercentage.value = percentTracker;
};

const updateTracker = (dt) => {
  const lerped = expDecay(
    lerpedPercentage.value,
    trackerPercentage.value,
    10,
    dt / 1000
  );

  if (circleWhite.value) {
    const circumference = 2 * Math.PI * 5; // 2πr, où r est le rayon du cercle (5 dans notre cas)
    const offset = circumference - (lerped / 100) * circumference;

    circleWhite.value.style.strokeDashoffset = `${offset}px`;
  }

  lerpedPercentage.value = lerped;
};

watch(currentIndex, (newValue, oldValue) => {
  updateCirclePosition(newValue);

  hideContent(oldValue);
});

const hideContent = (oldVal) => {
  if (currentIndex.value === oldVal) return;

  categoryShipping.value[oldVal].classList.remove("--active");
  categoryLink.value[oldVal].classList.remove("--active");

  const oldValChars = [
    ...categoryTitle.value[oldVal].querySelectorAll(".--char"),
    ...categoryShipping.value[oldVal].querySelectorAll(".--char"),
  ];

  ctx.add(() => {
    if (categoryShipping[oldVal]) {
      gsap.to(categoryShipping[oldVal], {
        "--width": 0 + "%",
      });
    }

    gsap.to(oldValChars, {
      yPercent: 100,
      overwrite: true,
      duration: 0.8,
      ease: "expo.out",
      stagger: 0.005,
      overwrite: true,
    });
  });
};

const updateContent = (newVal) => {
  if (!categoryShipping.value[newVal]) return;

  categoryShipping.value[newVal].classList.add("--active");

  const newValChars = [
    ...categoryTitle.value[newVal].querySelectorAll(".--char"),
    ...categoryShipping.value[newVal].querySelectorAll(".--char"),
  ];

  ctx.add(() => {
    if (categoryShipping[newVal]) {
      gsap.to(categoryShipping[newVal], {
        "--width": 100 + "%",
      });
    }

    gsap.to(newValChars, {
      yPercent: 0,
      duration: 1,
      ease: "expo.out",
      stagger: 0.005,
    });
  });
};

const updateCirclePosition = (index, immediate) => {
  const categoryPosition = categoryLink.value[index];
  const top = categoryPosition.offsetTop;

  categoryLink.value[index].classList.add("--active");

  gsap.to(circle.value, {
    y: top,
    duration: immediate ? 0 : 0.3,
    ease: "power2.out",
  });
};

// START HERE

let isScrolling = false;
let lastWheelEvent = null;

const jumpTo = (index: number) => {
  // Fonction appellée au clic sur un bouton

  hideContent(currentIndex.value);

  gsap.delayedCall(0.35, () => {
    updateContent(index);
  });

  scrollTo(index, false);
  currentIndex.value = index;
};

const onDebouncedScroll = () => {
  if (isMobile.value) return;

  if (oldIndex.value !== currentIndex.value) {
    updateContent(currentIndex.value);
    oldIndex.value = currentIndex.value;
  }
};

const debounceWheel = debounce(150, () => {
  if (isMobile.value || desktopEnabled.value === false) return;
  if (lastWheelEvent) {
    const result = lethargy.check(lastWheelEvent);
    if (result !== false) {
      isScrolling = true;
      scrollTo(currentIndex.value, true);
      setTimeout(() => {
        isScrolling = false;
      }, 1000); // Ajustez ce délai selon la durée de votre animation de défilement
    }
    lastWheelEvent = null;
  }
});

const onScroll = (e) => {
  e.preventDefault();
  e.stopPropagation();
  lastWheelEvent = e;
  debounceWheel();
};

const scrollTo = (index, checkIfPinned) => {
  if (checkIfPinned === true && isPinned.value === false) return;

  const destination = window.innerHeight + window.innerHeight * index;

  $lenis.scrollTo(destination, {
    duration: 1,
    onComplete: () => {
      isScrolling = false;
    },
  });
};

const debounceScroll = debounce(15, onDebouncedScroll);

const initListeners = () => {
  window.addEventListener("resize", onResize);
};

const initDesktopListeners = () => {
  window.addEventListener("wheel", debounceScroll);
  window.addEventListener("wheel", onScroll, { passive: false });
};

const onAllProductsClick = (e) => {
  e.preventDefault();

  let redirect;

  switch (currentIndex.value) {
    case 0:
      redirect = "/floors";
      break;
    case 1:
      redirect = "/walls-ceilings";
      break;
    case 2:
      redirect = "/kitchens";
      break;
    case 3:
      redirect = "/baths";
      break;
    default:
      redirect = "/wardrobe";
      break;
  }

  router.push(redirect);
};

const onResize = () => {
  updateCirclePosition(currentIndex.value, true);
};

const cleanDesktopListeners = () => {
  window.removeEventListener("wheel", debounceScroll);
  window.removeEventListener("scroll", onScroll);
};

const shutDesktop = () => {
  debounceScroll.cancel();
  debounceWheel.cancel();
  desktopEnabled.value = false;
  ctx?.kill(false);
  gsap.ticker.add(updateTracker);
  window.removeEventListener("resize", onResize);
  cleanDesktopListeners();
};

onBeforeUnmount(() => {
  shutDesktop();
});
</script>

<style lang="scss" scoped>
.homepage-category-list {
  width: 100%;
  position: relative;
  height: calc(v-bind(count) * 100vh);
  z-index: 1;
  // overflow: hidden;
  display: none;
  @include desktop {
    display: block;
  }
}

.categories-container {
  display: block;
  width: 100%;
  height: 100lvh;
  position: sticky;
  top: 0;
}

.images-container {
  width: 100%;
  height: 100lvh;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}

.content {
  height: 100svh;
  position: relative;
}

.links-wrapper {
  @include grid-responsive;
  padding: 6.4rem var(--padding-mobile) 0;

  @include desktop {
    position: absolute;
    bottom: 4rem;
    width: 100%;
    padding: 0 $padding-desktop;
  }
}

.links-container {
  grid-column: 1 / span 6;
  z-index: 10;

  @include desktop {
    grid-column: 11 / span 2;
  }
}

.category-link-list {
  margin-bottom: 3.5rem;
  position: relative;
}

.circle {
  display: none;
  @include desktop {
    display: block;
    position: absolute;
    left: -20px;
    top: 1px;
  }
}

.category-link {
  @include link;
  text-transform: uppercase;
  color: rgba($color: #ffffff, $alpha: 0.5);

  transition: 0.6s ease;

  &.--active {
    color: rgba($color: #ffffff, $alpha: 1);
  }

  @include desktop {
    &:hover {
      color: rgba($color: #ffffff, $alpha: 1);
    }
  }
}

.titles-container {
  position: absolute;
  left: 0;
  bottom: 0;
  min-height: 50rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}

.c-l {
  position: absolute;
  bottom: 1.5rem;
  left: 1.2rem;
  display: inline-block;
  @include desktop {
    left: 2.5rem;
    bottom: 4rem;
    width: 110rem;
  }
  span {
    display: inline-block;
  }
}

.category-title {
  @include headline-big-a;
  color: $white;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  position: relative;
  left: -0.8rem;
  overflow: visible;
}

.shipping-info {
  --width: 0%;
  @include caption-medium;
  color: $white;
  width: fit-content;
  position: relative;

  &::after {
    transition: 0.4s ease;
    content: "";
    width: var(--width);
    height: 1px;
    background-color: $white;
    position: absolute;
    left: 0;
    bottom: 0.15rem;
  }
  @include desktop {
    bottom: 0;
  }
}

.c-l :deep(.--line) {
  display: inline-block;
  overflow: hidden;
}

.gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4150253851540616) 29%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30rem;
  z-index: 10;
}

.all-products-container {
  overflow: hidden;
  position: relative;
  height: 4rem;
}

.others-container {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  width: 12rem;
  display: inline-block;
  @include link;
  color: white;
  text-transform: uppercase;
  visibility: hidden;
  a {
    @include link;
    @include link-active-full-hover;
    &::before,
    &::after {
      background-color: $white;
    }
  }
}
</style>
